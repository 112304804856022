import React, {useEffect, useState} from "react";
import {roundPrice} from "../../utils/roundPrice";
import RatingStar from "../rating/Star";
import Image from "next/image";
import {stripHtml} from "string-strip-html";
import ProductVariantSelectors from "./variant/Selectors";
import ProductAddToCart from "./AddToCart";
import ProductWishlist from "./Wishlist";
import {setSelectedProduct} from "../../stores/product/productSelectorSlice";
import ProductBadge from "./Badge";
import imageService from "../../services/product/imageService";
import {convertBxAttributes} from "../../utils/bxAttributes";
import ProductCompare from "./Compare";
import Link from "next/link";
import availableVariantService from "../../services/product/availableVariantService";
import {imageSizes} from "../../utils/imageSizes";
import productLinkService from "../../services/product/productLinkService";
import WishlistItemMenu from "../wishlist/item/Menu";

interface Props {
    product: any;
    informationOnly?: boolean;
    heading?: number;
    listing?: boolean;
    className?: string;
    reviewTotal?: boolean;
    style?: React.CSSProperties;
    showDescription?: boolean;
    wishlistMenu?: boolean;
    wishlists?: any;
}

const ProductTile = (
    {
        product,
        informationOnly = false,
        heading = 2,
        listing = true,
        className = "",
        reviewTotal = true,
        style,
        showDescription = false,
        wishlistMenu = false,
        wishlists
    }: Props) => {
    const [stateProduct, setStateProduct] = useState<any>(product || {});
    const minimumStock = availableVariantService.getMinimumStock(product?.variants || []);

    useEffect(() => {
        const initializeProduct = () => {
            const p = {...product};

            try {
                if (!p.cover.includes("cdn.perfecthair.ch")) {
                    p.cover = getCover(p, p);
                }
            } catch (e) {
            }

            if (p.variants) {
                const variant = availableVariantService.getAvailable(p.variants, p.detailId);
                if (variant) {
                    p.cover = getCover(p, variant);
                    Object.assign(p, variant);
                    p.isExpiring = Number(variant.isExpiring);
                    p.link = `${variant.link}?id=${variant.id}`;
                }
            }

            setStateProduct(p);
        };

        initializeProduct();
    }, []);

    const getCover = (p: any, mainVariant: any) => {
        if (p.cover && p.cover.includes("cdn.perfecthair.ch")) return p.cover;
        return mainVariant.imgDetailUrls
            ? imageService.getImage(mainVariant.imgDetailUrls, p.orderNumber)
            : imageService.getImage(mainVariant.imgMainUrls, p.orderNumber);
    };

    const onVariantChange = (id: any) => {
        const variant = product.variants.find((v: any) => v.id === parseInt(id));
        const main = product.variants.find((v: any) => product.mainDetailId === v.id);

        if (!variant || !main) return;

        const updatedProduct = {...product, ...variant};
        updatedProduct.cover = variant.imgDetailUrls
            ? imageService.getImage(variant.imgDetailUrls, variant.orderNumber)
            : imageService.getImage(variant.imgMainUrls, variant.orderNumber);

        setStateProduct(updatedProduct);
        setSelectedProduct(updatedProduct);
    };

    const addToCart = () => {
        if (stateProduct.isExpiring === 1 && stateProduct.stock <= 0) return null;
        if (stateProduct.lastStock && stateProduct.stock <= 0) return null;
        if (!stateProduct.lastStock && stateProduct.stock <= minimumStock) return null;
        return <ProductAddToCart product={stateProduct} listing={listing}/>;
    };

    const renderProductImage = () => {
        if (!stateProduct.imagePath && !stateProduct.cover) return <></>

        return (
            <Image
                src={stateProduct.imagePath ?? stateProduct.cover}
                alt={stateProduct.articleName ?? stateProduct.name}
                width={175}
                height={175}
                sizes={imageSizes({xs: "125px", md: "175px"})}
                className="mw-100 mh-100 w-auto h-auto"
            />
        )
    }

    const renderWishlistCta = () => {
        if (!wishlistMenu) return <ProductWishlist product={stateProduct}/>;
        return <WishlistItemMenu lineItem={product} lists={wishlists}/>
    }

    return (
        <div
            {...convertBxAttributes(stateProduct, `product-tile flex-fill position-relative dark-theme-bg-white ${className || ''}`)}
            style={style}>
            <Link
                href={productLinkService.getLink(stateProduct)}
                prefetch={false}
                className="d-block dark">
                <ProductBadge product={stateProduct}/>
                <div className="image-container d-flex align-items-end justify-content-center my-3 mx-auto">
                    {renderProductImage()}
                </div>

                <div className="product-tile-text product-tile-title mb-2 fw-normal">
                    {stateProduct.supplierName ?? stateProduct.brand}
                </div>
                {heading === 2 ? (
                    <h2 className="product-tile-text fs-8 fw-normal multi-line mt-0 mb-2">
                        {stateProduct.articleName ?? stateProduct.name}
                    </h2>
                ) : (
                    <h3 className="product-tile-text fs-8 fw-normal multi-line mt-0 mb-2">
                        {stateProduct.articleName ?? stateProduct.name}
                    </h3>
                )}

                {stateProduct.price && !informationOnly && (
                    <div className="flex flex-row">
                        <span className="fw-bold">{roundPrice(stateProduct.price)}</span>
                        <span className="text-decoration-line-through ms-2 pseudo-price">
                            {roundPrice(stateProduct.price) !== roundPrice(stateProduct.pseudoPrice) && (
                                <span>{roundPrice(stateProduct.pseudoPrice)}</span>
                            )}
                        </span>
                    </div>
                )}

                {!informationOnly && (
                    <RatingStar
                        rating={stateProduct.reviews}
                        total={stateProduct.reviewTotal}
                        className=""
                        showTotal={reviewTotal}
                        showRating={false}
                    />
                )}
            </Link>

            {!informationOnly && (
                <div className="variant-container mt-3">
                    <ProductVariantSelectors
                        variants={stateProduct.variants}
                        product={stateProduct}
                        onVariantChange={(id: number) => onVariantChange(id)}
                    />
                </div>
            )}

            {product.orderNumber && !informationOnly && (
                <div className="actions p-0">
                    <div className="row g-1 w-100">
                        <div className="col-3">
                            <ProductCompare product={stateProduct}/>
                        </div>
                        <div className="col-3">
                            {renderWishlistCta()}
                        </div>
                        <div className="col-6">{addToCart()}</div>
                    </div>
                </div>
            )}

            <Link
                href={productLinkService.getLink(stateProduct)}
                passHref
                prefetch={false}
                className="d-block dark">
                {showDescription && (
                    <p>{stripHtml(stateProduct.descriptionLong).result.substring(0, 500)}...</p>
                )}
            </Link>
        </div>
    );
};

export default ProductTile