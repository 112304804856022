import apiClient from "../../utils/apiClient";

class CustomerService {
    async getCustomer(additional: boolean = false) {
        try {
            const {data: customer, status: status} = await apiClient.post("/api/customer", {additional});
            if (status === 401 || !customer) {
                console.error('failed to fetch customer');
                return false;
            }
            return customer
        }
        catch(error) {
            return false;
        }
    }
}

const customerService = new CustomerService()
export default customerService