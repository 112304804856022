import * as React from 'react';
import {useState} from 'react';
import {alpha, styled} from '@mui/material/styles';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons';
import i18next from "i18next";
import cartService from "../../../services/cartService";
import wishlistService from "../../../services/wishlistService";
import {closeWishlist} from "../../../stores/wishlist/wishlistSlice";
import {openCart} from "../../../stores/cart/cartSlice";
import {useDispatch} from "react-redux";
import {ArrowLeftIcon} from '@mui/x-date-pickers/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {useRouter} from "next/router";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface Props {
    lineItem: any,
    lists: any
}

export default function WishlistItemMenu({lineItem, lists}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open: boolean = Boolean(anchorEl);
    const dispatch = useDispatch();
    const [menuState, setMenuState] = useState('default');
    const router = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function addItemToCart() {
        await cartService.addToCart(lineItem.orderNumber);
        await loadCart();
    }

    async function removeItem() {
        await wishlistService.removeItem(lineItem.basketItemId);
        return router.push(router.asPath);
    }

    async function moveItem(listId: number) {
        await wishlistService.moveItem(lineItem.basketItemId, listId);
        return router.push(router.asPath);
    }

    async function loadCart() {
        dispatch(closeWishlist());
        dispatch(openCart());
    }

    return (
        <div>
            <button type="button"
                    name={"btn-wishlist-menu"}
                    onClick={handleClick}
                    aria-label={"Wishlist"}
                    className={`btn btn-alt w-100 pt-1 pb-1 bx-narrative-button h-100`}>
                <FontAwesomeIcon icon={faEllipsisVertical}/>
            </button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {menuState === 'default' && [
                    <MenuItem key="remove-item" onClick={removeItem} disableRipple>
                        <DeleteIcon/>
                        {i18next.t('general.remove').toString()}
                    </MenuItem>,
                    <Divider key="divider-2" sx={{my: 0.5}}/>,
                    <MenuItem key="move-item" onClick={() => setMenuState('move')} disableRipple>
                        <DriveFileMoveIcon/>
                        {i18next.t('general.move').toString()}
                    </MenuItem>
                ]}

                {menuState === 'move' && [
                    <MenuItem key="back" onClick={() => setMenuState('default')} disableRipple>
                        <ArrowLeftIcon/>
                        {i18next.t('general.back').toString()}
                    </MenuItem>,
                    <Divider key="divider-3" sx={{my: 0.5}}/>,
                    ...lists.map((list: any) => (
                        <MenuItem key={list.id} onClick={() => moveItem(list.id)} disableRipple>
                            <span>{list.name}</span>
                        </MenuItem>
                    ))
                ]}
            </StyledMenu>
        </div>
    );
}