class AvailableVariantService {
    getAvailable(variants: any[], selectedId: number): any {
        const minimumStock = this.getMinimumStock(variants)

        const selected = variants.find((v: any) => v.id === selectedId);
        if (selected && this.isAvailable(selected, minimumStock)) {
            return selected;
        }

        const main = variants.find((v: any) => v.id === v.isMain);
        if (main && this.isAvailable(main, minimumStock)) {
            return main;
        }

        const firstAvailable = variants.find((v: any) => this.isAvailable(v, minimumStock));
        return firstAvailable ?? selected ?? main ?? variants[0];
    }

    getMinimumStock(variants: any) {
        try {
            return Math.min(variants[0].minimumStock, variants[0].supplierMinimumStock, Number(process.env.NEXT_PUBLIC_PRODUCT_MINIMUM_STOCK));
        }
        catch(e) {
            return Number(process.env.NEXT_PUBLIC_PRODUCT_MINIMUM_STOCK)
        }
    }

    private isAvailable(variant: any, minimumStock: number) {
        return !(variant.isExpring == 1 && variant.stock <= 0
            || variant.lastStock == 1 && variant.stock <= 0
            || variant.lastStock == 1 && variant.stock <= minimumStock
            || variant.stock <= 0);
    }
}

const availableVariantService = new AvailableVariantService();
export default availableVariantService;
