import apiInternal from "../../utils/apiInternal";
import spClient from "./spClient";

class SpRegionService {
  async getRegion(zipCode: number, city: string) {
    let result = await apiInternal().post('/api/v2/sp/region', {zipCode: zipCode, city: city});

    if (result.status !== 200) return;
    const canton = result.data.canton;
    if (canton && canton.length) return canton;

    const results = await Promise.all([this.fetchRegion(zipCode), this.fetchRegion(city)]);

    for (let result of results) {
      const canton = await this.saveResponse(result.data);
      if (!canton) continue;
      return canton;
    }

    return "";
  }

  saveResponse(response: any) {
    if (!response || !response.records.length) return false;
    const fields = response.records[0].fields;
    apiInternal().post('/api/v2/sp/region/insert', {data: fields}).then();
    return fields.kanton;
  }

  fetchRegion(value: any) {
    return spClient.get(`${spClient.regionPath}${value}`);
  }
}

const spRegionService = new SpRegionService();
export default spRegionService