import apiClient from "../utils/apiClient";
import apiInternal from "../utils/apiInternal";
import cookieOptions from "../utils/cookieOptions";
import localStorageService from "./storage/localStorageService";
import customerService from "./customer/customerService";
import i18next from "i18next";

class WishlistService {
    async lists() {
        const [sessionId, customer] = await Promise.all([
            wishlistService.cookie(),
            customerService.getCustomer()
        ]);

        if (!customer) return false;

        const {data: wishlist} = await apiInternal().post('/api/v2/wishlist/list', {
            sessionId,
            userId: customer.id,
            language: i18next.language
        });
        return wishlist;
    }

    async load() {
        try {
            const {data} = await apiClient.post('/api/wishlist/list');
            return data;
        } catch (e) {
            return [];
        }
    }

    reload(data: any) {
        if (data) return this.load();
        return false;
    }

    async addList(name: string) {
        return apiClient.post('/api/wishlist/list/add', {name: name});
    }

    async removeList(id: number) {
        return apiClient.post('/api/wishlist/list/remove', {id: id});
    }

    async mergeList(sourceId: number, targetId: number) {
        return apiClient.post('/api/wishlist/list/merge', {id: sourceId, targetId});
    }

    async rename(id: number, name: string) {
        return apiClient.post('/api/wishlist/list/rename', {id: id, name: name});
    }

    async addItem(id: number, orderNumber: string) {
        return apiClient.post('/api/wishlist/item/add', {basketId: id, orderNumber: orderNumber});
    }

    async removeItem(id: number) {
        return apiClient.post('/api/wishlist/item/remove', {id: id});
    }

    async moveItem(id: number, listId: number) {
        return apiClient.post('/api/wishlist/item/move', {id, listId});
    }

    async cookie(): Promise<string> {
        const storageKey: string = 'wishlist';
        let sessionId: any = localStorageService.getItem(storageKey);
        if (sessionId) return sessionId;

        const result: any = await apiInternal().get('/api/v2/wishlist/cookie');
        sessionId = result.data;
        let expireDate: Date = new Date();
        expireDate.setFullYear(expireDate.getFullYear() + 1);

        localStorageService.setItem(storageKey, sessionId, cookieOptions(expireDate));
        return sessionId;
    }
}

const wishlistService = new WishlistService();
export default wishlistService