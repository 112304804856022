import * as cookie from 'cookie';
import {getCookie} from "cookies-next";

class CookieService {
  getCustomerGroup(context?: any): string {
    const c = this.getCustomerCookie(context);
    if (typeof c !== 'string') return 'EK';
    try {
      const customer = JSON.parse(c);
      if (['PFI', 'LC'].includes(customer?.customerGroup)) return 'EK';
      return (customer?.customerGroup ?? 'EK');
    } catch {
      return 'EK';
    }
  }

  getCustomerCookie(context?: any) {
    try {
      if (context) return cookie.parse(context.req.headers.cookie).customer
      return getCookie('customer');
    }
    catch(e) {
      return false
    }
  }

  getCustomerId(context?: any) {
    const customer: any = this.getCustomerCookie(context);
    if (!customer) return null;

    try {
      return JSON.parse(customer).id;
    }
    catch(e) {
      return null;
    }
  }

  stringify(cookies: any) {
    let cookieString: string = '';

    Object.keys(cookies).map((key: string) => {
      cookieString += `${key}=${cookies[key]};`;
    })

    return cookieString;
  }
}

const cookieService = new CookieService();
export default cookieService
